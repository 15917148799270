import { default as React, useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    SxProps,
    TextField,
    Typography,
    alpha
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { toBase64 } from "utils/toBase64";

import { MultiFilePreview } from "./upload";
import { FORMAT_IMG, FORMAT_VIDEO, getFileType } from 'utils/getFileFormat';
import Iconify from 'components/Iconify';
import { useStoragesListQuery, useUploadFileMutation } from "../services/queries";
import { Storage, } from "services/queries";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import FileThumbnail from "./file-thumbnail";

interface UploadMediaToolImplementProps {
    data: {
        urls: Storage[],
        alignItems: 'center' | 'flex-start' | 'flex-end' | "stretch",
    }
    onChange: (data: Partial<UploadMediaToolImplementProps["data"]>) => void;
    onDeleteVideo?: (url: string) => void;
    onlyOne?: boolean;
    label?: React.ReactNode;
    labelSx?: SxProps;
    mimeTypes?: string[];
    hidePreview?: boolean;
    editorMediaShow?: boolean
}


export default function UploadMediaTool(props: UploadMediaToolImplementProps) {
    const {onlyOne, hidePreview = false} = props;
    const {enqueueSnackbar} = useSnackbar();

    const {t} = useTranslation();
    const theme = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);
    const inputBoxRef = useRef<HTMLInputElement>(null);


    const [mediaOpen, setMediaOpen] = useState(props.editorMediaShow ?? false);
    const [urls, setUrls] = useState<(any)[]>(props.data.urls);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedMedia, setSelectedMedia] = useState<any | null>(null);

    const handleHover = (event: any, media: any) => {
        setAnchorEl(event.currentTarget ?? event.target ?? null);
        setSelectedMedia(media);
    };



    const onClose = () => {
        setMediaOpen(false);
    }

    const [filter, setFilter] = useState({
        page: 1,
        perPage: 500,
        keyword: "",
    });


    useEffect(() => {
        setMediaOpen(props.editorMediaShow ?? false);
    }, [props.editorMediaShow]);

    const {data: mediaList, loading: fetching} = useStoragesListQuery({
        variables: {
            keyword: filter.keyword,
            take: filter.perPage,
            skip: (filter.page - 1) * filter.perPage,
            filterByMimeType: props.mimeTypes ?? []
        },
    });


    const [uploadFileMutation, {loading: adding}] = useUploadFileMutation();

    useEffect(() => {
        setUrls(props.data.urls ?? [])
    }, [props]);

    console.log(mediaList)

    return (
        <div>
            <ThemeProvider theme={theme}>

                {
                    (urls.length > 0 && !hidePreview) && props.editorMediaShow === undefined ?
                    <MultiFilePreview
                        /* @ts-ignore */
                        urls={(urls ?? []).filter(i => i != null)}
                        showPreview
                        onRemove={(url) => {
                            setUrls(urls.filter((u: Storage) => u?.url !== url));
                            props.onChange({
                                urls: urls.filter(u => u?.url !== url),
                                alignItems: 'flex-start'
                            })
                        }
                        }/> : null
                }

                <Box sx={{display: props.editorMediaShow !== undefined ? 'none' : 'block'}} mt={1}>
                    <input
                        onChange={async (e) => {
                            const files = e.target.files
                            if (files && (files.length || 0) > 0) {
                                const file = files.item(0);
                                if (file) {
                                    const imageBase64 = await toBase64(file);

                                    await uploadFileMutation({
                                        variables: {
                                            createStorageInput: {
                                                file_name: file.name,
                                                base_64: imageBase64.toString(),
                                            },
                                        },
                                        onCompleted: () => {
                                            enqueueSnackbar(t('media_add_file_success_message'), {
                                                variant: 'success',
                                            });
                                        },
                                        update: (cache, {data}) => {
                                            cache.modify({
                                                fields: {
                                                    storagesList(cachedData) {
                                                        console.log({cachedData});
                                                        return {
                                                            ...cachedData,
                                                            nodes: [data?.uploadFile.storage, ...cachedData.nodes]
                                                        };
                                                    }
                                                }
                                            })
                                        }
                                    })
                                }

                            }

                        }}
                        ref={inputRef} type="file" style={{display: 'none'}}
                    />
                    <Box
                        sx={{
                            mt: 1,
                            display: 'flex',
                            padding: '24px',
                            cursor: 'pointer',
                            color: theme.palette.secondary.main,
                            borderRadius: '10px',
                            margin: '16px 0',
                            ...props.labelSx,
                        }}
                        onClick={() => {
                            setMediaOpen(true)
                        }}
                    >
                        {
                            props.label ?
                                <Box
                                    position={'relative'}
                                >
                                    {props.label}
                                    {props.mimeTypes?.includes("video/") && props.onDeleteVideo && props.data.urls.length > 0 ?
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.onDeleteVideo?.(props.data.urls?.[0].uuid ?? '')
                                            }}
                                            sx={{
                                                top: 6,
                                                p: '2px',
                                                right: -6,
                                                position: 'absolute',
                                                color: 'common.white',
                                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                                '&:hover': {
                                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                                },

                                            }}
                                        >
                                            <Iconify
                                                icon={'mingcute:close-circle-fill'}
                                                width={14}
                                                height={14}
                                            />
                                        </IconButton> : null
                                    }
                                </Box> : (
                                    <Typography>
                                        {t((onlyOne && urls.length > 0) ? "change_image_from_media" : 'add_image_from_media')}
                                    </Typography>
                                )
                        }

                    </Box>

                </Box>
                <Dialog fullWidth maxWidth={'md'}
                        open={mediaOpen}
                        onClose={(event: any, reason: any) => {
                            if (reason && reason == "backdropClick")
                                return;
                            onClose()
                        }}>
                    <DialogTitle>
                        {t('add_image_from_media')}
                    </DialogTitle>
                    <DialogContent>
                        {
                            fetching && <LinearProgress/>
                        }
                        <Box
                            mb={4}
                        >
                            <TextField
                                name={"keyword"}
                                label={t("global_label_search")}
                                fullWidth
                                variant={"outlined"}
                                value={filter.keyword}
                                onChange={(e) => {
                                    setFilter({...filter, keyword: e.target.value});
                                }}
                            />
                        </Box>
                        <Grid container spacing={2} alignItems={'stretch'}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box
                                    ref={inputBoxRef}
                                    onClick={() => {

                                        inputRef?.current?.click();
                                    }}
                                    sx={{
                                        border: `2px dashed #DDDDDD`,
                                        padding: '16px 16px',
                                        outline: 'none',
                                        position: 'relative',
                                        height: '200px',
                                        borderRadius: 5,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        '&:hover': {
                                            opacity: 0.5,
                                            cursor: 'pointer'
                                        }
                                    }}>
                                    {adding ? <CircularProgress/> :
                                        <Typography variant="body1" align={'center'} gutterBottom
                                                    color={'textSecondary'}>
                                            {t('asset_drop_zone_label')}
                                        </Typography>}
                                </Box>
                            </Grid>
                            {
                                (mediaList?.storagesList?.nodes || []).filter((i: any) => (urls.findIndex(u => ((typeof u === 'string') ? u : u?.url) === i?.url ?? '') === -1) && !String(i?.url).includes("VIDEO_CAPTION_PREFIX"))
                                    .map((media: any,) => {
                                        return <MediaComponent
                                            key={media.uuid}
                                            handleHover={handleHover}
                                            media={media}
                                            onClick={(mediaData: any) => {
                                                if (onlyOne) {
                                                    props.onChange({
                                                        urls: [mediaData],
                                                        alignItems: 'flex-start'
                                                    });
                                                    setUrls([mediaData]);
                                                    setMediaOpen(false);
                                                } else {
                                                    props.onChange({
                                                        urls: [mediaData, ...urls],
                                                        alignItems: 'flex-start'
                                                    });
                                                    setUrls([mediaData, ...urls]);
                                                    setMediaOpen(false);
                                                }
                                            }}
                                            urls={urls}
                                        />
                                    })
                            }
                        </Grid>
                        {
                            (mediaList?.storagesList?.totalCount ?? 0) > filter.perPage &&
                            <Box
                                sx={{
                                    mt: 5,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Pagination
                                    count={Math.ceil((mediaList?.storagesList?.totalCount ?? 0) / filter.perPage)}
                                    shape="rounded"
                                    hidePrevButton
                                    hideNextButton
                                    page={filter.page}
                                    onChange={(e, page) => {
                                        setFilter({
                                            ...filter,
                                            page: page
                                        })
                                    }}

                                />
                            </Box>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} variant={'contained'}>
                            {t('create_attribute_action')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </div>
    );
}


const MediaComponent = (props: {
    media: Storage,
    onClick: Function,
    handleHover: (event: any, media: any) => void,
    urls: (string | any)[]
}) => {
    const {media, onClick, handleHover, urls} = props;
    const file = media?.url;
    const fileType = getFileType(file ?? "");

    const [loadingCaption, setLoadingCaption] = useState(false)
    return <Grid
        item xs={12} sm={6} md={4}
        onClick={(e: any) => {
            handleHover(e, media)
        }}
    >
        <Box
            onClick={() => {
                onClick(media)
            }}
            sx={{
                height: '200px',
                position: 'relative',
                borderRadius: '5px',
                overflow: 'hidden',
                backgroundColor: "white",
                cursor: urls.findIndex(u => ((typeof u === 'string') ? u : u?.url) === media?.url ?? '') !== -1 ? 'default' : 'pointer',
                '& img': {
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                },
                border: '0.1px solid',
                borderColor: 'text.secondary',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {
                FORMAT_IMG.includes(fileType) ? (
                    <CardMedia
                        component="img"
                        src={media?.url ?? ""}
                        sx={{
                            borderRadius: '5px',
                        }}
                    />
                ) : FORMAT_VIDEO.includes(fileType) ? (
                    <Box
                        sx={{
                            position: "absolute",
                            background: "transparent",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            margin: "auto",
                            color: 'text.primary',
                            backdropFilter: 'blur(5px)',
                        }}>
                        {loadingCaption
                            ? <CircularProgress/>
                            : <Iconify
                                icon="fluent:play-32-regular" /*  icon="fa-solid:play" */
                                width={50} height={50}
                                sx={{
                                    ':hover svg': {
                                        width: 80,
                                        height: 80
                                    }
                                }}
                            />
                        }
                    </Box>
                ) : (
                    <Card
                        sx={{
                            p: 2.5,
                            width: 1,
                            maxWidth: 222,
                            boxShadow: 0,
                            bgcolor: 'background.default',
                            height: "100%"
                        }}
                    >
                        <FileThumbnail
                            file={media}
                        />
                        <Typography
                            variant="caption"
                            sx={{
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {media?.alternative_title ?? ""}
                        </Typography>
                    </Card>
                )
            }

        </Box>
    </Grid>
}
